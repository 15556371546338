<template>
  <div class="httpUrl">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label=" EMM服务地址：" prop="httpUrl">
        <el-input v-model="ruleForm.httpUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item label=" 分配服务URL：" prop="allotUrl">
        <el-input v-model="ruleForm.allotUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item label=" 分配心跳地址：" prop="heartUrl">
        <el-input v-model="ruleForm.heartUrl" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item label=" 分配心跳端口：" prop="heartPorts">
        <el-input v-model="ruleForm.heartPorts" size="small" style="width: 200px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    const validateHttpUrl = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error('请配置EMM服务地址 http或者https'))
      } else {
        undefined

        if (
          !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            value
          )
        ) {
          undefined

          callback(new Error('EMM服务地址必须是正确的服务器地址'))
        } else {
          undefined

          callback()
        }
      }
    }
    const validateAllotUrl = (rule, value, callback) => {
      undefined
      if (value === '') {
        undefined

        callback(new Error('请配置EMM服务地址 http或者https'))
      } else {
        undefined

        if (
          !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
            value
          )
        ) {
          undefined

          callback(new Error('EMM服务地址必须是正确的服务器地址'))
        } else {
          undefined

          callback()
        }
      }
    }
    // const validateHeartUrl = (rule, value, callback) => {
    //   undefined
    //   if (value === '') {
    //     undefined

    //     callback(new Error('请输入分配心跳地址'))
    //   } else {
    //     undefined

    //     if (!/^[0-9]*$/.test(value)) {
    //       undefined

    //       callback(new Error('分配心跳地址必须是正确的心跳地址'))
    //     } else {
    //       undefined

    //       callback()
    //     }
    //   }
    // }
    return {
      ruleForm: {
        httpUrl: '', //EMM服务地址
        allotUrl: '', //分配服务URL
        heartUrl: '', //分配心跳地址
        heartPorts: '' //分配心跳端口
      },
      rules: {
        httpUrl: [
          {
            required: true,
            message: '请配置EMM服务地址 http或者https',
            trigger: 'blur'
          },
          { validator: validateHttpUrl, trigger: 'blur' }
        ],
        allotUrl: [
          {
            required: true,
            message: '请配置分配服务URL http或者https',
            trigger: 'blur'
          },
          { validator: validateAllotUrl, trigger: 'blur' }
        ],
        heartUrl: [
          { required: true, message: '请输入分配心跳地址', trigger: 'blur' }
        ],
        heartPorts: [
          { required: true, message: '请输入分配心跳端口', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    //保存接口
    async save() {
      var params = {}
      const res = await this.$axios.post('', params, true)
    },
    //点击保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.httpUrl {
  .demo-ruleForm {
    margin: 20px 0px 0px 50px;
  }
}
</style>
